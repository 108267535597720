import React from 'react'

import Button from '../Button'
import Collapsible from 'react-collapsible';
import Border from '../Border'
import Product from '../Product'
import AddProduct from '../AddProduct';

import H2 from './editable/H2'
import Img from './editable/Img'
import P from './editable/P'
import Section from './editable/Section'

import ScriptCache from "../../utils/ScriptCache"
import General from '../../utils/General'
import Backend from '../../utils/Backend'

import Titles from './editable/Titles';

const PRODUCTS_NO_OPTIONS = [
  {
    label: "3",
    value: 3
  },
  {
    label: "6",
    value: 6
  },
  {
    label: "9",
    value: 9
  }
]

export default class Block40 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      editable: props.editable,
      products: [],
      categories: [],
    }

    this.updateContentBlock = General.debounce(() => {
      let data = { ...this.state.data }
      data.product_category = data.product_category ? data.product_category.id : null
      Backend.updateContentBlock(data)
    }, 1000, false)
  }

  componentDidMount(){
    this._load()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _load(){
    let {
      data
    } = this.state

    this.setState({ loading: true })
    Backend.getProducts(data.products_no, data.product_category)
    .then(products => {
      this.setState({ products, loading: false })
      return Backend.getProductCategories()
    })
    .then(categories => {
      this.setState({ categories })
    })
    .catch(error => {
      this.setState({ loading: false })
    })
  }

  _update(data, callback){
    this.setState({ ...data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  _getModalContent(data){
    let {
      products,
      categories
    } = this.state
    return (
      <>
        <Border>
          <Collapsible
            trigger="Category of products to be displayed"
          >
            <br/>
            <div className="input-select">
              <select
                value={data.product_category ? data.product_category.id : null}
                onChange={e => {
                  let id = parseInt(e.target.value)
                  let productCategory = categories.find(category => category.id == id)
                  data.product_category = productCategory
                  this._update({
                    data,
                    loading: true
                  }, () => this._load())
                }}
              >
                { data.product_category == null &&
                  <option value="null">Select a Product Category</option>
                }
                {
                  categories.map(category => {
                    return (
                      <option value={category.id}>{ category.name }</option>
                    )
                  })
                }
              </select>
            </div>
          </Collapsible>
        </Border>


        <Border>
          <Collapsible
            trigger="Number of products to be displayed"
          >
            <br/>
            <div className="input-select">
              <select
                value={data.products_no}
                onChange={e => {
                  data.products_no = parseInt(e.target.value)
                  this._update({
                    data,
                    loading: true
                  }, () => this._load())
                }}
              >
                {
                  PRODUCTS_NO_OPTIONS.map(option => {
                    return (
                      <option value={option.value}>{ option.label }</option>
                    )
                  })
                }
              </select>
            </div>
          </Collapsible>
        </Border>

      </>
    )
  }

  _renderProducts(products){
    let {
      loading
    } = this.state

    if(loading){
      return this._renderProductsLoading()
    }

    return products.map(product => {
      return (
        <Product
          key={product.id}
          product={product}
          onAddToCartPressed={(product, variant) => this.props.onAddToCartPressed(product, variant)}
        />
      )
    })
  }

  _renderProductsLoading(){
    return (
      <>
        <Product/>
        <Product/>
        <Product/>
      </>
    )
  }

  _renderContent(){
    let {
      data,
      loading,
      editable,
      products
    } = this.state

    let shop_url = `/shop/category/`

    if(data.product_category){
      shop_url += data.product_category.slug
    }

    if(!loading && products.length == 0){
      return <AddProduct/>
    }

    let viewAllClassName = " mt--2  ";
    if (window.IS_CARD_STYLE_WEBSITE) {
      viewAllClassName = " dc-mt-1 ";
    }

    return (
      <>
        <Titles
          data={ data }
          editable={ editable }
          onUpdated={data => this._update(data)}
          rowCssClasses={"dc-mb-1"}
          alignment={"text-left"}
          headingNumber={2}
          headingDefaultText={"Product Category"}
        />

        <hr className='dc-d-none' />

        <div class="row mt-5 dc-row-v3">

          { this._renderProducts(products) }

        </div>

        <div
          className={ viewAllClassName +' text-center ' }
        >
          <a
            class="btn btn--primary type--uppercase"
            href={shop_url}
          >
            <span class="btn__text">View All Products</span>
            {/*
            <span class="label product-sale-price product-sale-price-darken">Sale</span>
            */}
          </a>
        </div>
      </>
    )
  }

  render(){
    let { data, editable, products, loading } = this.state

    if(!loading && !editable && products.length === 0){
      return null
    }

    let blockClassName = " block-40 product-blocks  ";
    if (window.IS_CARD_STYLE_WEBSITE) {
      blockClassName += " deep-cardify";
    }

    return (

        <Section
          data={ data }
          index={ this.props.index }
          className={ blockClassName }
          editable={ editable }
          onUpdated={data => this._update({ data })}
          onDelete={() => this.props.onDelete()}
          primaryColor={false}
          secondaryColor={false}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          renderModalContent={() => this._getModalContent(data)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
        >
          <div class="container">

            { this._renderContent() }

          </div>
        </Section>

    )
  }
}
