import React from 'react'

import Collapsible from 'react-collapsible';

import Border from '../Border'

import H1 from './editable/H1'
import P from './editable/P'
import Section from './editable/Section'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

import Titles from './editable/Titles';

export default class Block8 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      editable: props.editable
    }

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _update(data, callback){
    this.setState({ data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  _getModalContent(data){
    return (
      <Border>
        <Collapsible
          trigger="Text Alignment"
        >
          <br></br>
          <div className="input-select">
            <select
              value={data.variation || "center"}
              onChange={e => {
                data.variation = e.target.value
                this._update(data)
              }}
            >
              <option value="center">Centred</option>
              <option value="left">Left Aligned</option>
              <option value="right">Right Aligned</option>
            </select>
          </div>
        </Collapsible>
      </Border>
    )
  }

  render(){
    let { data, editable } = this.state

    let textAlign = "center"
    if(data.variation === "left"){
      textAlign = "left"
    }else if(data.variation === "right"){
      textAlign = "right"
    }

    return (
      <Section
          data={ data }
          index={ this.props.index }
          className={ " block-08 text-center bg--primary "+this.props.className }
          editable={ editable }
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          renderModalContent={() => this._getModalContent(data)}
          primaryColor={false}
          secondaryColor={false}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div className="container">

            <Titles
              data={ data }
              editable={ editable }
              onUpdated={data => this._update(data)}
              alignment={"text-"+textAlign}
              headingCssClasses="h2 h1-font-size"
            />

          </div>
      </Section>
    )
  }
}
