import React from 'react'

import Border from '../Border'

import H4 from './editable/H4'
import Img, { Sizes } from './editable/Img'
import P from './editable/P'
import Span from './editable/Span'
import Button from '../Button'
import Section from './editable/Section'

import TextInfoInputItem from '../sectioninputs/TextInfoInputItem'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

import Titles from './editable/Titles';

export default class Block47 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      editable: props.editable,
      uuid: "cb_"+General.uuid()
    }

    this.section = React.createRef();

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _update(data, callback){
    this.setState({ data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  render(){
    let { data, editable, uuid } = this.state

    if(data.settings.msc_enabled){
      data.settings.quaternary_color = window.CURRENT_PAGE_PRIMARY_COLOR
    }

    return (
      <Section
          ref={this.section}
          data={ data }
          index={ this.props.index }
          className={ " block-47 switchable switchable--switch " + this.props.className }
          editable={ editable }
          onUpdated={data => {
            data.settings.msc_enabled = false
            this._update(data)
          }}
          onDelete={() => this.props.onDelete()}
          primaryColorTitle="Title Colour"
          secondaryColorTitle="Text Colour"
          quaternaryColorTitle="Circle + Number Colour"
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div class="container">

            <Titles
              data={ data }
              editable={ editable }
              onUpdated={data => this._update(data)}
              headingNumber={2}
            />

              <div class="row justify-content-between align-items-center">
                  <div class="col-md-6 text-center">
                    <Img
                      img={ data.foreground_image }
                      size={ Sizes.MEDIUM }
                      block={47}
                      priority={this.props.index}
                      alt={ "" }
                      className="border--round"
                      editable={ editable }
                      onUpdated={image => {
                        data.foreground_image = image
                        this._update(data)
                      }}
                    />
                    <span>
                      <Span
                        text={ data.body}
                        color={ data.settings.secondary_color }
                        editable={ editable }
                        onUpdated={text => {
                          data.body = text
                          this._update(data)
                        }}
                      />
                    </span>
                  </div>
                  <div class="col-md-6 col-lg-5">
                      <ol class="process-3">
                          { data.text_infos.map((textInfo, index) => {
                            return (
                              <li class="process_item">
                                  <div class="process__number"
                                    style={{
                                      borderColor: data.settings.quaternary_color
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: data.settings.quaternary_color
                                      }}
                                    >
                                      { index + 1}
                                    </span>
                                  </div>
                                  <div class="process__body">
                                      <H4
                                        text={ textInfo.title }
                                        color={ data.settings.primary_color }
                                        editable={ editable }
                                        textAlign="left"
                                        className="m-b-0"
                                        onUpdated={text => {
                                          textInfo.title = text
                                          data.text_infos[index] = textInfo
                                          this._update(data)
                                        }}
                                      />
                                      <P
                                        text={ textInfo.body }
                                        color={ data.settings.secondary_color }
                                        editable={ editable }
                                        textAlign="left"
                                        onUpdated={text => {
                                          textInfo.body = text
                                          data.text_infos[index] = textInfo
                                          this._update(data)
                                        }}
                                      />
                                  </div>
                              </li>
                            )
                          })
                        }
                      </ol>
                  </div>
              </div>
          </div>
      </Section>
    )
  }
}
