import React from "react";
import "./App.css";

import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { toast } from "react-toastify";
import ContextMenu from "react-context-menu";

import "./assets/css/stack-interface.css";
import "./assets/css/socicon.css";
import "./assets/css/iconsmind.css";
import "./assets/css/bootstrap.css";
import "./assets/css/lightbox.min.css";
import "./assets/css/flickity.css";
import "./assets/css/stack-interface.css";
import "./assets/css/theme.css";
import "./assets/css/grapick.min.css";
import "./assets/icons/plugins/flaticon2/flaticon.css";
import "./assets/css/aos.css";
import "./assets/css/custom.css";
import "./assets/scss/app.scss";

import "./constants/Api";
import "./constants/General";

import Menu from "./components/Menu";
import Footer from "./components/Footer";
import Page from "./components/Page";
import WebsiteloadingPlaceholder from "./components/WebsiteloadingPlaceholder";

import PasswordModal from "./components/modal/PasswordModal";
import WebsiteModal from "./components/modal/WebsiteModal";
import ShareModal from "./components/modal/ShareModal";

import General from "./utils/General";
import Backend from "./utils/Backend";
import Cart from "./utils/Cart";
import Notify from "./utils/Notify";
import AuthManager from "./utils/AuthManager";

import Fonts from "./utils/Fonts";
import Event from "./utils/Event";

import Product from "./pages/Product";
import Shop from "./pages/Shop";
import Booking from "./pages/Booking";
import Bookings from "./pages/Bookings";
import Checkout from "./pages/Checkout";
import Article from "./pages/Article";
import Blog from "./pages/Blog";
import Package from "./pages/Package";
import NotFound from "./pages/NotFound";
import DataDetail from "./pages/DataDetail";

import TagManager from "react-gtm-module";
import Color from "./utils/Color";

import ColorScheme from "color-scheme"
import Website from "./utils/Website";

toast.configure();

window.FIXED_COLORS = ['transparent', '#ffffff', '#efefef', '#333333']

window.MSC_COLORS = ['#fa6160', '#e69138', '#FFD141','#6aa84f', '#3d85c6',   '#674ea7', '#DE3976',   '#efefef', '#333333']
window.MSC_COMPLIMENTARY_COLOURS = [...window.MSC_COLORS, ...window.FIXED_COLORS]

const MSC_COMPLIMENTARY_COLOURS_ENABLED = true

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    this.menu = React.createRef();
    this.footer = React.createRef();
    this.page = React.createRef();
    this.shop = React.createRef();
    this.booking = React.createRef();
    this.bookings = React.createRef();
    this.product = React.createRef();
    this.checkout = React.createRef();
    this.article = React.createRef();
    this.blog = React.createRef();
    this.package = React.createRef();
    this.data = React.createRef();

    this.rightClickMenu = React.createRef();
  }

  componentDidMount() {
    this._setup();
    Event.on('SHOW_NOTICE_BANNER', () => this.setState({
      showTrialExpired: true
    }));
  }

  _updateComplimentaryColors(baseColor){
    if(!MSC_COMPLIMENTARY_COLOURS_ENABLED || !baseColor){
      return
    }

    baseColor = baseColor.replace("#", "")
    var scheme = new ColorScheme;
    scheme.from_hue(0).from_hex(baseColor).scheme('tetrade').distance(0.83)

    var tetradeColors = scheme.colors().map(color => "#"+color);

    let colors = [
      tetradeColors[3],
      tetradeColors[5],
      tetradeColors[9],
      tetradeColors[12],
    ]

    scheme.from_hue(0).from_hex(baseColor).scheme('triade').distance(0.83)

    let complimentaryColors = scheme.colors().map(color => "#"+color);

    if(tetradeColors.includes("#ffffff")){
      window.MSC_COMPLIMENTARY_COLOURS = ['#fa6160', '#e69138', '#FFD141','#6aa84f', '#3d85c6',   '#674ea7', 'transparent',   '#efefef', '#333333']
      return
    }

    colors = [
      colors[0],
      complimentaryColors[11],
      colors[1],
      colors[2],
      colors[3],
      ...window.FIXED_COLORS
    ]

    window.MSC_COMPLIMENTARY_COLOURS = colors
  }

  _authenticate() {
    this.setState({ showPasswordModal: true });
  }

  _closeRightClickMenu() {
    this.rightClickMenu.current.closeContextMenu();
  }

  _getRightClickMenuItems() {
    let { editable } = this.state;

    let contextItems = [
      {
        label: "Menu",
        onClick: () => {},
      },
    ];

    contextItems.push({
      label: "Share",
      onClick: () => {
        this.setState({ showShareModal: true });
        this._closeRightClickMenu();
      },
    });

    if (!editable) {
      contextItems.push({
        label: "Login",
        onClick: () => {
          this.setState(
            {
              editable: true,
            },
            () => this._authenticate()
          );
          this._closeRightClickMenu();
        },
      });
    }

    if (editable) {
      contextItems.push({
        label: "Logout",
        onClick: () => {
          AuthManager.removeCredentials();
          this.setState({ editable: false });
          this._closeRightClickMenu();
          window.location.reload();
        },
      });
    }

    contextItems.push({
      label: "Close Menu",
      onClick: () => this._closeRightClickMenu(),
    });

    return contextItems;
  }

  _setTracking(website) {
    let { editable } = this.state;

    let gtmId = editable ? window.Api.GTMID : website.gtm_id;

    if (!website || !gtmId) {
      return;
    }

    const tagManagerArgs = {
      gtmId: editable ? window.Api.GTMID : website.gtm_id,
    };

    TagManager.initialize(tagManagerArgs);
  }

  _showSubscriptionModal(){
    let { website, editable } = this.state
    let { subscription, notice } = website

    if ((!notice?.active && !subscription?.payment_flow) || (subscription?.active && !notice?.active)) {
      return false;
    }

    let paymentFlow = subscription.payment_flow

    if((!paymentFlow.triggered && paymentFlow.type !== "post_sign_up") && !notice?.active){
      return false
    }

    if(!editable){
      return notice?.active || paymentFlow.delinquent_treatment === "display_warning"
    }

    return true
  }

  _isDev(){
    let websiteUrl = window.location.host;

    return websiteUrl.indexOf("herokuapp.com") > -1 ||
           websiteUrl.indexOf("localhost") > -1
  }

  async _setup(retry = true) {
    let websiteUrl = window.location.host;

    if (this._isDev()) {
      websiteUrl = "lavender.globalwebsites.ai";
      if(General.getUrlParameter('website')){
        websiteUrl = General.getUrlParameter('website');
      }
    } else if (window.location.protocol !== "https:") {
      window.location.replace(
        `https:${window.location.href.substring(
          window.location.protocol.length
        )}`
      );
    }

    let pathname = window.location.pathname;

    let pageSlug = null;
    let articleSlug = null;
    let productSlug = null;
    let packageSlug = null;

    let shopMode = false;
    let blogMode = false;
    let packageMode = false;
    let bookingMode = false;
    let dataMode = false;

    if (pathname != null) {
      pathname = pathname.substring(1, window.location.pathname.length);
      shopMode = pathname.indexOf("shop") == 0;
      blogMode = pathname.indexOf("news") == 0;
      packageMode = pathname.indexOf("sign-up") == 0;
      bookingMode = pathname == "book" || pathname == "bookings";
      dataMode = pathname.indexOf("data") == 0;

      if (!shopMode && !blogMode && !packageMode && !bookingMode && !dataMode) {
        pageSlug = pathname || "index";
      } else if (shopMode && pathname.indexOf("shop/product/") !== -1) {
        productSlug = pathname.replace("shop/product/", "");
      } else if (blogMode && pathname !== "news") {
        articleSlug = pathname.replace("news/article/", "");
      } else if (packageMode && pathname !== "sign-up") {
        packageSlug = pathname.replace("sign-up/", "");
      } else if (dataMode) {
        pageSlug = "index"
      }
    }

    let editable = false;
    let show404 = false;

    let data = {};
    try {
      data = await Backend.findWebsite(
        websiteUrl,
        pageSlug,
        articleSlug,
        productSlug,
        packageSlug,
        shopMode || pageSlug || bookingMode
      );
      editable = data.editable;
      AuthManager.currentUser = data.current_user;

      if (pageSlug && !data.page) {
        show404 = true;
      }
      if (productSlug && !data.product) {
        show404 = true;
      }
      if (articleSlug && !data.article) {
        show404 = true;
      }
      if (shopMode && !data.shop) {
        show404 = true;
      }
      if (packageMode && !data.package) {
        show404 = true;
      }
    } catch (error) {
      console.error("Unexpected Error: ", error);
      AuthManager.removeCredentials();
      if (retry) {
        this._setup(false);
        return;
      }
      this.setState({ show404: true, loading: false });
      return;
    }

    this._updateComplimentaryColors(data.website.primary_color)

    let cart = null;
    window.CURRENT_WEBSITE_SHOP_CURRENCY = ""
    if (data.shop) {
      cart = Cart.init(data.shop);
      window.CURRENT_WEBSITE_SHOP = data.shop;
      window.CURRENT_WEBSITE_SHOP_CURRENCY = data.shop.currency;
    }

    if (data.website) {
      window.SHOW_HIDDEN_BLOCKS = false
      window.HAS_HIDDEN_BLOCKS = false
      window.FIRST_HIDDEN_BLOCKS = false
      window.CURRENT_WEBSITE = data.website;
      window.CURRENT_WEBSITE_ID = data.website.id;
      window.CURRENT_PAGE_PRIMARY_COLOR = data.website.primary_color;
      window.CURRENT_PAGE_PREVIEW = data.website.preview_image;
      window.CURRENT_PAGE_PRIMARY_COLOR_DARKENED = Color.brightness(
        window.CURRENT_PAGE_PRIMARY_COLOR,
        -20
      );
      window.CURRENT_COMPANY_ID = data.website.company_id;
      window.CURRENT_VISITOR_TOKEN = data.visitor_token;
      window.HIDE_LIVE_CHAT =
        data.website.hide_live_chat == null
          ? true
          : data.website.hide_live_chat;
      window.HIDE_PAYMENT_WIDGET = !data.website.subscription.payment_widget_enabled
      if(data.shop && data.website.subscription.payment_widget_enabled){
        window.HIDE_PAYMENT_WIDGET = !!data.shop.hide_payment_widget;
      }
      window.HIDE_CART = !data.website.subscription.shop_enabled
      if(data.shop && data.website.subscription.shop_enabled){
        window.HIDE_CART = !!data.shop.hide_cart;
      }
      window.CURRENT_WEBSITE_DESIGN_STYLE = data.website.design_style;
      if( window.CURRENT_WEBSITE_DESIGN_STYLE === "card"){
        window.IS_CARD_STYLE_WEBSITE = true
      }
      if(!data.website.background_color){
        data.website.background_color = '#dfdfdf'
      }
      window.CURRENT_WEBSITE_BACKGROUND_COLOR = data.website.background_color;
    }

    if (data.page) {
      window.CURRENT_PAGE_SLUG = data.page.slug;
      window.CURRENT_PAGE_ID = data.page.id;
    }

    if (data.uploaded_fonts) {
      let fonts = [];
      for (let i = 0; i < data.uploaded_fonts.length; i++) {
        let font = data.uploaded_fonts[i];
        Fonts.addUploaded(font);
      }
      Fonts.loadAll();
    }

    this.setState(
      {
        cart,
        show404,
        websiteUrl,
        pageSlug,
        shopMode,
        blogMode,
        packageMode,
        bookingMode,
        dataMode,
        menu: data.menu,
        footer: data.footer,
        page: data.page,
        article: data.article,
        pkg: data.package,
        website: data.website,
        shop: data.shop,
        product: data.product,
        loading: false,
        canRenderContent: this.props.canRenderContent,
        showPasswordModal: data.password !== "valid",
        passwordError:
          data.password === "invalid"
            ? "Password is incorrect, please try again"
            : null,
        showCustomModal: false,
        canAnimate: false,
        animatingContentBlockKey: null,
        editable,
      },
      () => {
        this._setTracking(data.website)

        if(this._showSubscriptionModal()){
          window.addEventListener('scroll', function() {
            if (window.scrollY >= window.innerHeight*.5) {
              if(editable){
                Event.emit("SHOW_SUBSCRIPTION_MODAL");
              }else{
                Event.emit("SHOW_NOTICE_BANNER");
              }
            }
          });
        }

      }
    );
  }

  _updateWebsite = General.debounce(
    (website) => {
      this._updateComplimentaryColors(website.primary_color)
      this.setState({ website });
      Backend.updateWebsite(website);
    },
    1000,
    false
  );

  _renderPasswordModal() {
    let { loading, editable, websiteUrl, passwordError, showPasswordModal } =
      this.state;

    return (
      <PasswordModal
        show={showPasswordModal}
        edit={editable}
        loading={loading}
        error={passwordError}
        websiteUrl={websiteUrl}
        onHide={() =>
          this.setState({
            showPasswordModal: false,
            editable: false
          }, () => {
            setTimeout(() => General.updateAll(), 1000)
          })
        }
        onLoggedIn={() => {
          this._setup()
          setTimeout(() => General.updateAll(), 1000)
        }}
      />
    );
  }

  _renderAllFonts() {
    let fontHTML = "";
    window.FONTS.map((font) => {
      if (font.url) {
        fontHTML += `
          @font-face {
            font-family: "${font.familyName}";
            src: url('${font.url}');
          }
        `;
      }

      fontHTML += `
        .${font.className} {
          font-family: "${font.familyName}";
        }
      `;
      font.weights.map((weight) => {
        fontHTML += `
          .${font.className}-${weight.value} {
            font-weight: ${weight.value};
          }
        `;
      });
    });
    return <style dangerouslySetInnerHTML={{ __html: fontHTML }} />;
  }

  _renderTrialExpires() {
    let { website } = this.state
    let { subscription } = website
    let { notice } = website

    let title = "Website Under Maintenance"
    if(notice?.active && notice?.text){
      title = notice.text
    }
    else if(subscription.payment_flow.visitor_title){
      title = subscription.payment_flow.visitor_title
    }

    let textColor = '#fff'
    let backgroundColor = window.CURRENT_PAGE_PRIMARY_COLOR

    if(notice?.active && notice?.text_color){
      textColor = notice.text_color
    }

    if(notice?.active && notice?.background_color){
      backgroundColor = notice.background_color
    }


    return (
      <>
        <div className="trial-expires main-container">
          <h1
            className="banner-h1"
            style={{
              color: textColor
            }}
          >{title}</h1>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html: `
              .trial-expires.main-container::before{
                background-color : ${backgroundColor};
              }
            `,
          }}
        />
      </>
    );
  }

  _renderWebsiteFonts() {
    let { website } = this.state;

    if (!website) {
      return null;
    }

    let websiteFonts = [];

    let tags = ["h1", "h2", "h3", "h4", "h5", "h6", "btn", "menu", "p"];

    for (var i = 0; i < tags.length; i++) {
      let tag = tags[i];
      let fontKey = tag + "_font";

      if (website[fontKey]) {
        let font = website[fontKey];

        if (tag === "menu") {
          websiteFonts.push(this._getFontStyle("a", font, true));
          websiteFonts.push(this._getFontStyle("h5", font, true));
          websiteFonts.push(this._getFontStyle("span", font, true));
        } else {
          websiteFonts.push(this._getFontStyle(tag, font));
        }

        if (tag === "btn") {
          websiteFonts.push(this._getFontStyle(tag, font, true));
        }

        if (tag === "p") {
          websiteFonts.push(
            this._getFontStyle("span:not([class^='h''])", font)
          );
        }

        if (tag === "h2") {
          websiteFonts = this._addSubHeaderFonts(websiteFonts, font, website);
        }
      }
    }

    return (
      <style dangerouslySetInnerHTML={{ __html: websiteFonts.join("") }} />
    );
  }

  _addSubHeaderFonts(websiteFonts, font, website) {
    // set h2 as the font and weight for other headers if none specified
    let headers = ["h3", "h4", "h5", "h6"];
    for (var i = 0; i < headers.length; i++) {
      let header = headers[i];
      let headerKey = header + "_font";
      let headerFont = website[headerKey];
      if (!headerFont || Object.keys(headerFont).length === 0) {
        websiteFonts.push(this._getFontStyle(header, font));
      }
    }

    return websiteFonts;
  }

  _getFontStyle(tag, font, menu = false) {
    if (!font || font.family_name == null) {
      return null;
    }

    let excludes =
      ":not(.boxed):not(.ignore-user-set-font):not(.render-preview-image)";
    let selector = `
      .main-container ${tag},
      .main-container .${tag}
    `;

    if (menu) {
      selector = `
        .nav-container ${tag}:not(.material-icons):not(.material-icons-two-tone):not(.logo-text):not(.slide-text),
        .nav-container .${tag}:not(.material-icons):not(.logo-text):not(.slide-text),
        footer ${tag}:not(.material-icons):not(.logo-text),
        footer .${tag}:not(.material-icons):not(.logo-text)
      `;
    }

    return `
      ${selector} {
        font-family: "${font.family_name}" !important;
        font-weight: ${font.weight} !important;
      }
    `;
  }

  _onAddToCartPressed(product, variant, quantity) {
    Cart.add(product, variant, quantity)
      .then((cart) => {
        this.menu.current.updateCart(cart);
        this.menu.current.showCartDropdown();
        this.setState({ cart });
      })
      .catch((error) => {
        Notify.error(error.message);
      });
  }

  _onSetCartItem(product, variant, quantity) {
    Cart.set(product, variant, quantity)
      .then((cart) => {
        this.menu.current.updateCart(cart);
        this.setState({ cart });
      })
      .catch((error) => {
        Notify.error(error.message);
      });
  }

  _onRemoveFromCart(product, variant, quantity) {
    Cart.remove(product, variant, quantity)
      .then((cart) => {
        this.menu.current.updateCart(cart);
        this.setState({ cart });
      })
      .catch((error) => {
        Notify.error(error.message);
      });
  }

  _onDashboardClicked() {
    let url = Website.getDashboardUrl(this.state.website);

    let editWebsiteTab = window.open();
    this.setState({ loadingHash: true });
    return Backend.createLoginHash()
      .then((hash) => {
        editWebsiteTab.location.href = url + "?h=" + hash.value + "&wid=" + this.state.website.id
        this.setState({ loadingHash: false });
      })
      .catch((error) => {
        window.open(url);
        this.setState({ loadingHash: false });
      });
  }

  _renderDefaultButtonStyles() {
    let { page, shop, website } = this.state;
    if (page == null && shop == null && website == null) {
      return null;
    }

    if (!website) {
      website = page ? page.website : shop.website;
    }

    website = { ...website };
    website.primary_btn_color = website.primary_color;
    website.secondary_btn_color = website.primary_color;

    let buttonBorderRadius = "6px";
    if (website.button_style === "rounded") {
      buttonBorderRadius = "50px";
    }

    if (website.button_style === "square") {
      buttonBorderRadius = "0px";
    }

    let editorBrandingColor = "#333333"
    if(!website.editor_branding_mode || website.editor_branding_mode === "company"){
      editorBrandingColor = website.primary_btn_color
    }
    else if(website.editor_branding_mode == "platform"){

    }


    return (
      <style
        dangerouslySetInnerHTML={{
          __html: `
          :root {
            --website-primary-color: ` + website.primary_color + ` ;
            --website-primary-color-o-90: ` + website.primary_color+'e6' + ` ;
            --card-style-website-card-background-color: ` + window.CURRENT_WEBSITE_BACKGROUND_COLOR + ` ;
          }
          .primary-color-swatch > div {
            background-color: ${website.primary_color};
          }
          .product-sale-price {
            background-color: ${website.primary_btn_color} !important;
            border-color: ${website.primary_btn_color};
          }
          .product-sale-price-darken{
            background-color:transparent !important;
          }
          .product-sale-price-darken:after {
            filter: brightness(90%);
            content:"";
            background-color: ${website.primary_btn_color} !important;
            border-color: ${website.primary_btn_color};
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 50px;
            z-index: -1;
          }
          .main-container a.btn--primary {
            background-color: ${website.primary_btn_color};
            border-color: ${website.primary_btn_color};
          }
          .main-container .btn--primary:hover {
            background-color: ${website.primary_btn_color};
          }
          .nav-container a.btn--primary {
            background-color: ${website.primary_btn_color};
            border-color: ${website.primary_btn_color};
          }
          a.nav-checkout svg path {
            fill: ${website.primary_btn_color};
          }
          .rdw-editor-wrapper:hover::after{
            background-color:${Color.brightness(website.primary_btn_color, 20, "60")} !important;
            border-color: ${Color.brightness(website.primary_btn_color, 20, "90")};
            border-radius: 0px;
          }
          .rdw-editor-wrapper .rdw-editor-toolbar {
            background-color: ${Color.saturate(Color.brightness(website.primary_btn_color, -20, "95"), 50)} !important;
          }
          .rdw-editor-wrapper .rdw-editor-toolbar:before{
            border-top-color: ${Color.saturate(Color.brightness(website.primary_btn_color, -20, "95"), 50)} !important;
          }
          .rdw-link-modal {
            background-color: ${Color.saturate(Color.brightness(website.primary_btn_color, -20), 30)} !important;
          }
          .edit,
          .nav-button-edit a,
          .section .settings .buttons a,
          .btn-fixed-left-container .btn,
          .add-content-block + .add-content-block-button .button {
            background-color: ${Color.saturate(Color.brightness(editorBrandingColor, -40, "50"), 10)};
          }
          .section .settings .buttons a.unhide{
            background-color: ${Color.saturate(Color.brightness(editorBrandingColor, -10, "80"), 10)};
          }
          .section .settings .buttons a.unhide:hover{
            background-color: ${Color.saturate(Color.brightness(editorBrandingColor, -10, "80"), 10)};
          }
          .edit:hover,
          .nav-button-edit a:hover,
          .section .settings .buttons a:hover,
          .btn-fixed-left-container .btn:hover,
          .add-content-block + .add-content-block-button .button:hover {
            background-color: ${Color.saturate(Color.brightness(editorBrandingColor, -60, "80"), 10)};
          }
          a.link--primary,
          .text--primary,
          .articles .article-body a{
            color: ${website.primary_btn_color};
          }
          .bg-c-primary{
            background-color: ${website.primary_btn_color};
          }
          .page.product .slider-product-nav li.slide.is-selected{
            border-color: ${website.primary_btn_color};
          }
          .nav-container .btn--primary:hover {
            background-color: ${website.primary_btn_color};
          }
          .main-container .btn:not([class*='primary']),
          .main-container .btn:not([class*='primary']):hover,
          .main-container .imagebg:not(.image--light) .btn:not([class*='primary']),
          .main-container .imagebg:not(.image--light) .btn:not([class*='primary']):hover {
            border-color: ${website.secondary_btn_color};
          }
          .main-container .btn:not([class*='primary']) span,
          .main-container .imagebg:not(.image--light) .btn:not([class*='primary']) span {
            color: ${website.secondary_btn_color};
          }
          .nav-container .btn:not([class*='primary']),
          .nav-container .btn:not([class*='primary']):hover,
          .nav-container .imagebg:not(.image--light) .btn:not([class*='primary']),
          .nav-container .imagebg:not(.image--light) .btn:not([class*='primary']):hover {
            border-color: ${website.secondary_btn_color};
          }
          .nav-container .btn:not([class*='primary']) span,
          .nav-container .imagebg:not(.image--light) .btn:not([class*='primary']) span {
            color: ${website.secondary_btn_color};
          }
          @media (min-width: 991px){
            .bar--transparent:not(.bar--dark):not(.pos-fixed) .btn:not([class*='primary']),
            .bar--transparent:not(.bar--dark):not(.pos-fixed) .btn:not([class*='primary']):hover {
              border-color:  ${website.secondary_btn_color};
            }
            .bar--transparent:not(.bar--dark):not(.pos-fixed) .btn:not([class*='primary']) .btn__text {
              color: ${website.secondary_btn_color};
            }
          }
          .main-container  .btn:not(.btn-products):not(.skip) ,
          .nav-container .btn,
          .cookie-banner .btn {
            border-radius: ${buttonBorderRadius} !important;
          }
          .btn.btn--icon i:not(.edit-button-i) {
            border-radius: ${buttonBorderRadius} 0 0 ${buttonBorderRadius} !important;
            color:inherit !important;
          }
          .rdp-day_selected,
          .rdp-day_selected:hover {
            background: ${website.primary_color};
          }
          .rdp-button:hover:not([disabled]):not(.rdp-day_selected){
            background: ${Color.brightness(website.primary_color, 20, "60")};
          }
          .rdp-day_today {
            font-weight: normal !important;
          }
          .async-select-paginate__option--is-selected,
          .async-select-paginate__option--is-focused {
            background: ${website.primary_color};
            color: white;
          }
          .async-select-paginate__option:hover {
            background: ${Color.brightness(website.primary_color, 20, "60")};
            color: white;
          }
          .async-select-paginate__control:hover,
          .async-select-paginate__control--is-focused,
          .async-select-paginate__control--is-selected {
            border-color: transparent;
            box-shadow: none;
            outline: none !important;
          }
        `,
        }}
      />
    );
  }

  _renderMainContent() {
    let {
      page,
      website,
      editable,
      shopMode,
      dataMode,
      blogMode,
      bookingMode,
      packageMode,
      showPasswordModal,
    } = this.state;

    if (shopMode) {
      return this._renderShopContent();
    }

    if (blogMode) {
      return this._renderBlogContent();
    }

    if (packageMode) {
      return this._renderPackageContent();
    }

    if (bookingMode){
      return this._renderBookingContent()
    }

    if (dataMode) {
      return this._renderDataDetail()
    }

    return (
      <Page
        ref={this.page}
        editable={editable}
        page={page}
        website={website}
        canShowDashboardButtons={!showPasswordModal}
        onShowWebsiteModalPressed={() =>
          this.setState({ showWebsiteModal: true })
        }
        onAddToCartPressed={(product, variant) =>
          this._onAddToCartPressed(product, variant, 1)
        }
        onDashboardClicked={() => this._onDashboardClicked()}
      />
    );
  }

  _renderBlogContent() {
    let { website, article } = this.state;

    return (
      <BrowserRouter>
        <Switch>
          <Route
            path="/news/article/:article_slug"
            render={(props) => {
              return (
                <Article
                  {...props}
                  ref={this.article}
                  website={website}
                  article={article}
                />
              );
            }}
          />
          <Route
            exact
            path="/news"
            render={(props) => {
              return <Blog {...props} ref={this.blog} website={website} />;
            }}
          />
        </Switch>
      </BrowserRouter>
    );
  }

  _renderShopContent() {
    let { cart, shop, product, website, pageSlug } = this.state;

    return (
      <BrowserRouter>
        <Switch>
          <Route
            path="/shop/product/:product_slug"
            render={(props) => {
              return (
                <Product
                  {...props}
                  ref={this.product}
                  website={website}
                  product={product}
                  onAddToCartPressed={(
                    product,
                    variant = null,
                    quantity = 1
                  ) => {
                    this._onAddToCartPressed(product, variant, quantity);
                  }}
                />
              );
            }}
          />
          <Route
            exact
            path="/shop/checkout"
            render={(props) => {
              return (
                <Checkout
                  ref={this.checkout}
                  cart={cart}
                  website={website}
                  shop={shop}
                  onSetCartItemPressed={(product, variant, quantity) => {
                    this._onSetCartItem(product, variant, quantity);
                  }}
                  onRemoveFromCartPressed={(
                    product,
                    variant = null,
                    quantity = 1
                  ) => {
                    this._onRemoveFromCart(product, variant, quantity);
                  }}
                  onCheckedOut={() => {
                    let cart = Cart.reset();
                    this.menu.current.updateCart(cart);
                    this.setState({ cart });
                  }}
                />
              );
            }}
          />
          <Route
            path="/shop"
            render={(props) => {
              return (
                <Shop
                  ref={this.shop}
                  shop={shop}
                  website={website}
                  pageSlug={pageSlug}
                  onAddToCartPressed={(product, variant, quantity = 1) => {
                    this._onAddToCartPressed(product, variant, quantity);
                  }}
                />
              );
            }}
          />
        </Switch>
      </BrowserRouter>
    );
  }

  _renderBookingContent() {
    let { website } = this.state;

    return (
      <BrowserRouter>
        <Switch>
          <Route
            path="/book"
            render={(props) => {
              return (
                <Booking
                  ref={this.booking}
                  website={website}
                />
              );
            }}
          />
          <Route
            path="/bookings"
            render={(props) => {
              return (
                <Bookings
                  ref={this.bookings}
                  website={website}
                />
              );
            }}
          />
        </Switch>
      </BrowserRouter>
    );
  }

  _renderDataDetail(){
    let { website } = this.state;

    return (
      <BrowserRouter>
        <Switch>
          <Route
            path="/data"
            render={(props) => {
              return (
                <DataDetail
                  ref={this.data}
                  website={website}
                />
              );
            }}
          />
        </Switch>
      </BrowserRouter>
    );
  }

  _renderPackageContent() {
    let { pkg, website } = this.state;

    return (
      <BrowserRouter>
        <Switch>
          <Route
            path="/sign-up/:package_slug"
            render={(props) => {
              return (
                <Package
                  {...props}
                  ref={this.package}
                  pkg={pkg}
                  website={website}
                />
              );
            }}
          />
        </Switch>
      </BrowserRouter>
    );
  }

  render() {
    let {
      website,
      editable,
      cart,
      page,
      menu,
      footer,
      shopMode,
      dataMode,
      blogMode,
      bookingMode,
      packageMode,
      showWebsiteModal,
      showShareModal,
      show404,
      loading,
      showTrialExpired,
      showPasswordModal,
    } = this.state;

    if (showPasswordModal) {
      return this._renderPasswordModal();
    }

    if (loading) {
      return (
        <WebsiteloadingPlaceholder
          shopMode={shopMode}
          blogMode={blogMode}
          packageMode={packageMode}
        />
      );
    }

    if (show404) {
      return <NotFound website={website} />;
    }

    return (
      <BrowserRouter>
        <div className="main-template">
          <div className="main-template-header">
            <Menu
              ref={this.menu}
              editable={editable}
              menu={menu}
              cart={cart}
              website={website}
              hideMenu={packageMode}
              hidePrimaryNavBar={shopMode || blogMode || packageMode || bookingMode || dataMode}
              renderModal={(content) => this.page.current.renderModal(content)}
              onUpdatedCart={(cart) => this.setState({ cart })}
              onApprovalRequestClicked={() => this._onDashboardClicked()}
            />
          </div>
          <div className="main-template-content">
            {this._renderMainContent()}
          </div>
          <div className="main-template-footer">
            <Footer
              ref={this.footer}
              editable={editable}
              website={website}
              footer={footer}
              hideFooter={packageMode || bookingMode}
              renderModal={(content) => this.page.current.renderModal(content)}
            />
          </div>
        </div>
        {this._renderAllFonts()}
        {this._renderWebsiteFonts()}
        {this._renderDefaultButtonStyles()}
        <WebsiteModal
          show={showWebsiteModal}
          page={page}
          website={website}
          menu={menu}
          onUpdated={(website) => {
            this._updateWebsite(website);
            this.menu.current.updateWebsite(website);
          }}
          onMenuUpdated={(menu) => {
            this.menu.current.updateMenu(menu);
          }}
          onPageSoftUpdated={(page) => {
            this.setState({ page });
          }}
          onMenuSoftUpdated={(menu) => {
            this.menu.current.updateMenu(menu, false);
          }}
          onHide={() => this.setState({ showWebsiteModal: false })}
        />
        {page && (
          <ContextMenu
            contextId="root"
            ref={this.rightClickMenu}
            items={this._getRightClickMenuItems()}
          />
        )}
        <ShareModal
          show={showShareModal}
          title="Share"
          message="Click below to share this site on various channels"
          shareTitle="Checkout This Site"
          url={window.location}
          onHide={() => this.setState({ showShareModal: false })}
          onAdded={() => this.setState({ showShareModal: false })}
        />
        {showTrialExpired && this._renderTrialExpires()}
      </BrowserRouter>
    );
  }
}
