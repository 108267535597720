import React from 'react'

import AsyncStorage from "../utils/AsyncStorage";

export default class CookieBanner extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      cookieBanner: props.website.cookie_banner,
      editable: props.editable
    }
  }

  componentDidMount() {
    AsyncStorage.getItem('cookiePolicy').then(cookie => {
      if(cookie == null){
        this.setState({showCookieBanner: true})
      }else{
        this.setState({showCookieBanner: false})
      }
    })
  }

  _closeCookiePolicyPopUp(){
    AsyncStorage.setItem('cookiePolicy', 'seen').then();
    this.setState({showCookieBanner: false})
  }

  render() {

    let {
      editable,
      cookieBanner,
      showCookieBanner,
    } = this.state

    if(!cookieBanner || !showCookieBanner || !cookieBanner.active || editable) return null

    return (
      <div className={`cookie-banner`}>

        {/* Starts : Banner */}
        <div
          className='banner position-bottom-right' // bottom-right || bottom-left || bottom || top
        >

          <div className='content'>
            <h5 className='title'>{cookieBanner.title}</h5>
            <p>{cookieBanner.message}</p>
          </div>

          <div className='buttons'>
            <div className='r1 row'>
              <div className='col'>
                <button
                  className='btn btn--primary type--uppercase w-100'
                  onClick={() => this._closeCookiePolicyPopUp()}
                >
                  Accept & Continue
                </button>
              </div>
            </div>
            {
              cookieBanner.show_reject &&
              <div className='r2 row'>
                <div className='col'>
                  <button
                    className='btn btn--secondary type--uppercase w-100'
                    onClick={() => {
                      window.location = cookieBanner.reject_redirect_url
                    }}
                  >
                    Reject
                  </button>
                </div>
              </div>
            }
          </div>

        </div>
        {/* End : Banner */}

      </div>
    )

  }

}
