import React from 'react'

import Collapsible from 'react-collapsible';
import Border from '../Border'

import H3 from './editable/H3'
import Img from './editable/Img'
import P from './editable/P'
import Span from './editable/Span'
import Section from './editable/Section'

import TextInfoInputItem from '../sectioninputs/TextInfoInputItem'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

import Titles from './editable/Titles';

export default class Block48 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      key: 0,
      data: props.data,
      editable: props.editable,
      uuid: "cb_"+General.uuid()
    }

    this.section = React.createRef();

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)

      this.setState({
        key: this.state.key + 1
      }, () => General.updateEasyPieCharts())
    }, 2000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      key: this.state.key + 1
    }, () => General.updateEasyPieCharts())
  }

  _update(data, callback){
    this.setState({ data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  _getModalContent(data){
    return (
      <Border>
        <Collapsible
          trigger="Titles"
        >
          <br/>
          { this._getModalContentCircles(data) }
        </Collapsible>
      </Border>
    )
  }

  _getModalContentCircles(data){
    let textInfos = data.text_infos
    let textInfosContent = textInfos.map((textInfo, index) => {
      return (
        <TextInfoInputItem
          key={index}
          index={index}
          titleName="Title"
          subtitleName="Percentage"
          bodyName="Text"
          textInfo={textInfo}
          canHideSubtitle={false}
          onUpdated={textInfo => {
            data.text_infos[index] = textInfo
            this._update(data)
          }}
          deletable={false}
        />
      )
    })

    return (
      <Border>
          { textInfosContent }
      </Border>
    )
  }

  render(){
    let { data, editable, uuid, key } = this.state

    let blockClassName = " block-48 text-center ";
    let rowClassName = " row ";
    let columnClassName = data.text_infos.length > 3 ? "col-md-6 col-lg-3" : "col-md-4"
    let dataSize="208"

    if (window.IS_CARD_STYLE_WEBSITE) {
      blockClassName += " deep-cardify";
      rowClassName += data.text_infos.length > 3 ? "dc-row-lg-v2" : ""
      columnClassName = "col-md item"
      dataSize="194"
    }

    if(data.settings.msc_enabled){
      data.settings.primary_color = window.CURRENT_PAGE_PRIMARY_COLOR
      data.settings.quaternary_color = window.CURRENT_PAGE_PRIMARY_COLOR
    }

    return (
      <Section
          ref={this.section}
          data={ data }
          index={ this.props.index }
          className={ blockClassName +this.props.className }
          editable={ editable }
          onUpdated={data => {
            data.settings.msc_enabled = false
            this._update(data)
          }}
          onDelete={() => this.props.onDelete()}
          primaryColorTitle="Circle Title Colour"
          secondaryColorTitle="Text Colour"
          quaternaryColorTitle="Circle Colour"
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          renderModalContent={() => this._getModalContent(data)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div key={key} class="container items">

            <Titles
              data={ data }
              editable={ editable }
              onUpdated={data => this._update(data)}
              headingNumber={2}
            />

              <div className={rowClassName}>
                  { data.text_infos.map((textInfo, index) => {
                      return (
                        <div class={columnClassName}>
                            <div class="feature dc-padding">
                                <div class="radial" data-value={textInfo.subtitle} data-size={dataSize} data-timing="1000" data-bar-width="8" data-color={data.settings.quaternary_color}>
                                  <H3
                                    text={ textInfo.title }
                                    color={ data.settings.primary_color }
                                    className="h3 radial__label"
                                    editable={ editable }
                                    onUpdated={text => {
                                      textInfo.title = text
                                      data.text_infos[index] = textInfo
                                      this._update(data)
                                    }}
                                  />
                                </div>
                                <P
                                  text={ textInfo.body }
                                  color={ data.settings.secondary_color }
                                  editable={ editable }
                                  textAlign="center"
                                  onUpdated={text => {
                                    textInfo.body = text
                                    data.text_infos[index] = textInfo
                                    this._update(data)
                                  }}
                                />
                            </div>
                        </div>
                      )
                    })
                  }
              </div>
          </div>
      </Section>
    )
  }
}
