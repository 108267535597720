import React from 'react'
import Collapsible from 'react-collapsible';
import Border from '../Border'

import H2 from './editable/H2'
import Img from './editable/Img'
import P from './editable/P'
import Span from './editable/Span'
import Section from './editable/Section'

import TextInfoInputItem from '../sectioninputs/TextInfoInputItem'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

import Titles from './editable/Titles';

export default class Block29 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      key: 0,
      editable: props.editable,
      uuid: "cb_"+General.uuid()
    }

    this.section = React.createRef();

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _update(data, callback){
    this.setState({ data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  _addTextInfo(data){
    let textInfo = {
      title: "Tab Title",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus lacus in lacus blandit placerat. Quisque et orci quis ex interdum ultricies. Phasellus molestie erat at tristique mattis. Donec libero augue, egestas.",
      settings: {}
    }

    data.text_infos.push(textInfo)
    this._update(data, () => General.updateTabs())
  }


  _getModalContent(data){
    return (
      <Border>
        <Collapsible
          trigger="Tabs"
        >
          <br/>
          { this._getModalContentList(data) }
        </Collapsible>
      </Border>
    )
  }

  _getModalContentList(data){
    let textInfos = data.text_infos
    let textInfosContent = textInfos.map((textInfo, index) => {
      return (
        <TextInfoInputItem
          key={index}
          index={index}
          textInfo={textInfo}
          renderSubtitle={false}
          onUpdated={textInfo => {
            data.text_infos[index] = textInfo
            this._update(data)
          }}
          onDelete={() => {
            if(data.text_infos.length == 2){
              alert("There must be at least 2 tab items")
              return
            }
            textInfos.splice(index, 1)
            data.text_infos = textInfos
            this._update(data, () => General.updateTabs())
          }}
        />
      )
    })

    return (
      <Border>
          { textInfosContent }
          <div className="row">
            <div className="col-md-12">
              <a className="btn"
                onClick={() => this._addTextInfo(data)}
              >
                  <span className="btn__text">
                      Add Tab
                  </span>
              </a>
            </div>
          </div>
      </Border>
    )
  }

  _renderTabs(data, editable, uuid){
    let textInfos = data.text_infos
    if(!textInfos){
      return null
    }

    return (
      <>
        <ul class="tabs">
          { this._renderTabTitles(data, textInfos, editable, uuid)}
        </ul>
        <ul class="tabs-content">
          { this._renderTabContent(data, textInfos, editable, uuid)}
        </ul>
      </>
    )
  }

  _renderTabTitles(data, textInfos, editable, uuid){
    return textInfos.map((textInfo, index) => {
      let className = index === 0 ? "active" : ""
      let id = "textInfo_"+textInfo.id
      return (
        <li className={className}>
          <div className="tab__title">
              <Span
                text={ textInfo.title}
                color={ data.settings.primary_color }
                textAlign="left"
                editable={ editable }
                className="h5"
                onUpdated={text => {
                  textInfo.title = text
                  textInfos[index] = textInfo
                  data.text_infos = textInfos
                  this._update(data)
                }}
              />
          </div>
        </li>
      )
    })
  }

  _renderTabContent(data, textInfos, editable){
    return textInfos.map((textInfo, index) => {
      let className = index === 0 ? "active" : ""
      let id = "textInfo_"+textInfo.id
      return (
        <li key={ id } id={ id } className={className}>
          <div className="tab__content">
              <P
                text={ textInfo.body }
                color={ data.settings.primary_color }
                editable={ editable }
                minRows={8}
                textAlign="left"
                onUpdated={text => {
                  textInfo.body = text
                  textInfos[index] = textInfo
                  data.text_infos = textInfos
                  this._update(data)
                }}
              />
          </div>
        </li>
      )
    })
  }

  render(){
    let {
      data,
      editable,
      key,
      uuid
    } = this.state

    if(data.settings.msc_enabled){
      data.settings.secondary_color = window.CURRENT_PAGE_PRIMARY_COLOR
    }

    return (
      <Section
          ref={this.section}
          data={ data }
          index={ this.props.index }
          className={ " block-29 " + this.props.className }
          editable={ editable }
          onUpdated={data => {
            data.settings.msc_enabled = false
            this._update(data)
          }}
          onDelete={() => this.props.onDelete()}
          primaryColorTitle="Text Colour"
          secondaryColorTitle="Highlight Colour"
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          renderModalContent={() => this._getModalContent(data)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div className="container">

            <Titles
              data={ data }
              editable={ editable }
              onUpdated={data => this._update(data)}
              headingNumber={2}
            />

              <div className="row justify-content-center">
                  <div className="col-md-12">
                      <div id={uuid} className="tabs-container tabs--vertical">
                          { General.renderTabStyle(data.settings, uuid) }
                          { this._renderTabs(data, editable, uuid) }
                      </div>
                  </div>
              </div>
          </div>
      </Section>
    )
  }
}
