import React from "react";

import H1 from "./H1";
import H2 from "./H2";
import H3 from "./H3";
import P from "./P";

export default class Titles extends React.Component {

  headings = {
    H1, H2, H3
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      editable: props.editable,
      rowCssClasses:props.rowCssClasses,
      columnCssClasses:props.columnCssClasses,
      alignment:props.alignment,
      headingNumber:props.headingNumber,
      headingCssClasses:props.headingCssClasses
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {

    let { data,
          editable,
          rowCssClasses,
          columnCssClasses,
          alignment,
          headingNumber,
          headingCssClasses
        } = this.state

    if (!(data.title || data.subtitle)) {
      return false
    }

    let titleColor = data.title_color;
    let SubtitleColor = data.subtitle_color;

    let rowCss = "row b-titles";
    if(rowCssClasses){
      rowCss+=" "+rowCssClasses;
    }

    let columnCss = "col-md-10 col-lg-8";
    if(columnCssClasses){
      columnCss = columnCssClasses
    }

    if(alignment === "text-left"){
      rowCss+=" text-left";
      columnCss="col-md-12";
    }

    if(alignment === "text-right"){
      rowCss+=" text-right";
      columnCss="col-md-12";
    }

    let headingCss = "b-heading";

    if(headingCssClasses){
      headingCss+=" "+headingCssClasses;
    }

    if(!headingNumber){
      headingNumber=1
    }

    const HeadTag = this.headings["H" + headingNumber];

    return (
      <div
        className={rowCss}
      >
        <div
          className={columnCss}
        >

          {data.title && (
            <>
              <HeadTag
                  text={data.title}
                  color={titleColor}
                  editable={editable}
                  className={headingCss}
                  onUpdated={(text) => {
                    data.title = text;
                    this.props.onUpdated(data);
                  }}
                />
            </>
          )}

          {data.subtitle && (
            <P
              text={data.subtitle}
              color={SubtitleColor}
              editable={editable}
              className={'b-sub-heading'}
              onUpdated={(text) => {
                data.subtitle = text;
                this.props.onUpdated(data);
              }}
            />
          )}

        </div>
      </div>
    )
  }
}
